import React from "react";
import Typed from "react-typed";

const AboutOne = () => {
  return (
    <div className="rwt-about-area rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-5">
            {/* <h1>frik.co, i need a picture here</h1> */}
            <div className="thumbnail">
              <img
                className="w-100 rounded"
                src="./images/about/codelaptop.jpeg"
                alt="About Images"
              />
            </div>
          </div>

          <div className="col-lg-7 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="section-title">
                <h2 className="title">
                  The Value of <br />{" "}
                  <Typed
                    className="theme-gradient"
                    strings={["Filthy Rich Incredible Kids"]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={5000}
                    loop
                  />
                </h2>
                <p>
                  Rich - has a broad meaning, we would thrive to be rich on
                  resource, ideas, and empowerment, we believe that every single
                  collaboration with us, should be filthy rich with features,
                  while at the same time, solving real world problems.
                </p>
                <p>
                  Incredible Kids - We, always thrive to be incredible in every
                  single occasion, and yet, on the other side, we’ll always
                  consider ourselves as kids. Because as a kids we will always
                  have curiosity, empathy, and also the humble will to learn.{" "}
                </p>
                {/* <div className="read-more-btn mt--40">
                  <a className="btn-default" href="#">
                    <span>More About Us</span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
