import React from "react";
import Typed from "react-typed";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from "../accordion/AccordionOne";

const mission = () => {
  return (
    <div className="rn-company-mission-are rn-section-gap">
      <div className="container">
        <div className="row row--30">
          <div className="col-lg-6">
            <div className="mission-title">
              <h2 className="title">
                Work with us, <br />{" "}
                <Typed
                  className="theme-gradient"
                  strings={[
                    "We're Faster.",
                    "We're Better.",
                    "We solve problems.",
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p>
                Lets face it, app developers and it companies are common in
                Indonesia.
              </p>

              <p>
                But after lurking around for a while. We found out, a lot of
                people dissatisfied with the works of the devs. And it
                devastated us, that the amount of the people dissatisfied with
                the result of the devs is mind-boggling. Therefore, we decided
                to join the few that deliver quality. AND also at the same time,
                delivering impeccable experience for you, our c̶o̶s̶t̶u̶m̶e̶r̶ new
                friend!
              </p>

              <div className="read-more-btn mt--50">
                <Link
                  className="btn-default btn-icon"
                  to={{ pathname: "https://wa.me/+6281330078999" }}
                >
                  Start The Collaboration{" "}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt_md--30 mt_sm--30">
            <AccordionOne customStyle="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default mission;
