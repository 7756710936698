import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            What is Frik?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            We're the problem solvers. We're satisfied when we could help others
            by our creations. Therefore. We created this company to help people,
            with the IT Industry as our focus.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Why filthy rich? Are you guys greedy?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            Rich - has a broad meaning, we would thrive to be rich on resource,
            ideas, and empowerment, we believe that every single collaboration
            with us, should be filthy rich with features, or even better, made
            both of us ended up as filthy riches 🤣🤣
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            Aaand, incredible kids?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            We, always thrive to be incredible in every single occasion, and
            yet, on the other side, we’ll always consider ourselves as kids.
            Because as a kids we will always have curiosity, empathy, and also
            the humble will to learn.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            I'm IN! Lets collaborate!
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>
            You can find the buttons everywhere on this landing page to start to
            collaborate with us! and you'll find the start to collaborate button
            nearby. We cannot wait, go and send us a message!
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
