import React from "react";
import Typed from "react-typed";

export default function MainTitle() {
  return (
    <h1 className="title theme-gradient display-two text-center">
      Create Your <br />{" "}
      <Typed
        strings={[
          "Website.",
          "Application.",
          "System.",
          "Business.",
          "Campaign.",
          "Everything.",
        ]}
        typeSpeed={80}
        backSpeed={5}
        backDelay={1000}
        loop
      />
    </h1>
  );
}
