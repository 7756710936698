import React from "react";
import Typed from "react-typed";
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderOne from "../common/header/HeaderOne";
import FrikService from "../elements/service/FrikService";
// import HeaderTopNews from "../common/header/HeaderTopNews";
import FooterTwo from "../common/footer/FooterTwo";
import Copyright from "../common/footer/Copyright";

import AboutOne from "../elements/about/AboutOne";
import ServiceFive from "../elements/service/ServiceFive";
import FrikCta from "../elements/calltoaction/FrikCta";
import FrikTeam from "../elements/team/FrikTeam";
import TestimonialThree from "../elements/testimonial/TestimonialThree";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import FrikTimeline from "../elements/timeline/FrikTimeline";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";

import MainTitle from "./typed/MainTitle";

var BlogListData = BlogClassicData.slice(0, 3);

const BusinessConsulting2 = () => {
  return (
    <>
      <SEO title="Home" />
      <main className="page-wrapper">
        {/* <HeaderTopNews /> */}
        <HeaderOne
          btnStyle="btn-small btn-icon"
          HeaderSTyle="header-transparent"
        />
        {/* <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" /> */}

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-1 height-850">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <MainTitle />
                <div className="text-center inner">
                  {/* <span className="subtitle">CREATORS</span> */}
                  {/* <h1 className="title theme-gradient display-two">
                    Create Your <br />{" "}
                    <Typed
                      strings={[
                        "Website.",
                        "Application.",
                        "System.",
                        "Business.",
                        "Campaign.",
                        "Everything.",
                      ]}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1> */}
                  <p className="description">
                    with collaboration that took your business into the next
                    level.
                  </p>
                  <div className="button-group">
                    <Link
                      className="btn-default btn-medium round btn-icon"
                      to={{ pathname: "https://wa.me/+6281330078999" }}
                    >
                      Create With Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                    <Link
                      className="btn-default btn-medium btn-border round btn-icon"
                      to={{ pathname: "https://wa.me/+6281330078999" }}
                    >
                      Contact Us{" "}
                      <i className="icon">
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <Separator />

        {/* Start About Area  */}
        <AboutOne />
        {/* End About Area  */}

        <Separator />
        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="What we can do for you"
                  title="What we can collaborate on"
                  description="There are many things we could collaborate on, but these three are our best feats"
                />
              </div>
            </div>
            <FrikService
              serviceStyle="service__style--1 radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div className="rwt-callto-action-area">
          <div className="wrapper">
            <FrikCta />
          </div>
        </div>
        {/* End Call To Action Area  */}

        {/* Start Team Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Meet your new partners"
                  title="Your Team"
                  description="We are excited as we take part in order to make your project awesome!"
                ></SectionTitle>
              </div>
            </div>
            <FrikTeam
              column="col-lg-3 col-md-6 col-12"
              teamStyle="team-style-default style-two"
            />
          </div>
        </div>
        {/* End Team Area  */}

        <Separator />
        {/* Start Timeline Area  */}

        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Timeline"
                  title="Working Process."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 mt--50">
                <FrikTimeline classVar="" />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}
        <Separator />
        {/* Start Mission Area   */}
        <Mission />
        {/* Start Mission Area  */}

        <Separator />
        {/* Start Testimonial Area  */}
        <div className="rwt-testimonial-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Client Feedback"
                  title="Testimonial."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <TestimonialThree teamStyle="" />
          </div>
        </div>
        {/* End Testimonial Area  */}

        <Separator />
        {/* <div className="blog-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Latests News"
                  title="Our Latest News."
                  description="We provide company and finance service for <br /> startups and company business."
                />
              </div>
            </div>
            <div className="row row--15">
              {BlogListData.map((item) => (
                <div key={item.id} className="col-lg-4 col-md-6 col-12 mt--30">
                  <BlogList StyleVar="box-card-style-default" data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};
export default BusinessConsulting2;
