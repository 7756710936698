import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import MobileMenu from "./MobileMenu";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  return (
    <>
      <header className={`rn-header header-default ${HeaderSTyle}`}>
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              <Logo
                image={`${process.env.PUBLIC_URL}/images/logo/frik-logo.png`}
              />
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="header-btn">
                  <Link
                    className={`btn-default ${btnStyle}`}
                    to={{ pathname: "https://wa.me/+6281330078999" }}
                  >
                    CREATE WITH US
                  </Link>
                </div>
                <div className="mobile-menu-bar ml--15 d-block d-lg-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
