import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
  {
    image: "happyawtian",
    name: "Awtian",
    designation: "Everything Officer",
    location: "Surabaya, Indonesia",
    description:
      "I love it when i create stuff that actually help people! I'm currently the sole member of Frik. But we will grow! :D",
    socialNetwork: [
      {
        icon: <FiFacebook />,
        url: "https://fb.com/awtian",
      },
      {
        icon: <FiTwitter />,
        url: "https://twitter.com/awtian",
      },
      {
        icon: <FiInstagram />,
        url: "https://instagram.com/awtian",
      },
      {
        icon: <FiLinkedin />,
        url: "https://www.linkedin.com/in/awtian/",
      },
    ],
  },
  // {
  //   image: "team-02",
  //   name: "Busyairi",
  //   designation: "Excecutive Officer",
  //   location: "Gresik",
  //   description:
  //     "Yes, I am a product designer. I have a passion for product design.",
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiLinkedin />,
  //       url: "#",
  //     },
  //   ],
  // },
  // {
  //   image: "team-03",
  //   name: "Cecha",
  //   designation: "Engineering Officer",
  //   location: "Gresik",
  //   description:
  //     "Yes, I am a product designer. I have a passion for product design.",
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiInstagram />,
  //       url: "#",
  //     },
  //   ],
  // },
  // {
  //   image: "team-03",
  //   name: "Ogy",
  //   designation: "Engineering Officer",
  //   location: "Surabaya",
  //   description:
  //     "Yes, I am a product designer. I have a passion for product design.",
  //   socialNetwork: [
  //     {
  //       icon: <FiFacebook />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiTwitter />,
  //       url: "#",
  //     },
  //     {
  //       icon: <FiInstagram />,
  //       url: "#",
  //     },
  //   ],
  // },
];

const TeamTwo = ({ column, teamStyle }) => {
  return (
    <div className="row row--15 justify-content-center">
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <div className="inner">
                <figure className="thumbnail">
                  <img
                    src={`./images/team/${data.image}.jpg`}
                    alt="Corporate React Template"
                  />
                </figure>
                <figcaption className="content">
                  <h2 className="title">{data.name}</h2>
                  <h6 className="subtitle theme-gradient">
                    {data.designation}
                  </h6>
                  <span className="team-form">
                    <img
                      src="./images/team/location.svg"
                      alt="Corporate React Template"
                    />
                    <span className="location">{data.location}</span>
                  </span>
                  <p className="description">{data.description}</p>

                  <ul className="social-icon social-default icon-naked mt--20">
                    {data.socialNetwork.map((social, index) => (
                      <li key={index}>
                        <a href={`${social.url}`}>{social.icon}</a>
                      </li>
                    ))}
                  </ul>
                </figcaption>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default TeamTwo;
